import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { FormHandler } from "../../components/data";
import { ChangePasswordFormContent } from "../user/AccountDetails";
import { useNavigate, useSearchParams } from "react-router-dom";

function RequestResetForm(props) {
  return (
    <Box component="form" onSubmit={props.handleSubmit}>
      <Typography>
        Enter the email associated with your account to reset your password.
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        inputProps={{
          maxLength: 100
        }}
      />

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
    </Box>
  );
}

export function RequestPasswordResetPage(props) {
  const successHandler = (r, setMessage) => {
    setMessage({
      severity: "success",
      text:
        "Please check your emails, including spam folders, and use the link provided"
    });
  };
  return (
    <FormHandler
      successHandler={successHandler}
      postURL="/api/public/requestPasswordReset"
    >
      <RequestResetForm />
    </FormHandler>
  );
}

export function ResetPasswordPage(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = React.useState({
    email: searchParams.get("email"),
    challenge: searchParams.get("challenge")
  });

  const successHandler = (r, setMessage) => {
    navigate("/login");
  };

  return (
    <FormHandler
      data={data}
      setData={setData}
      postURL="/api/public/resetPassword"
      successHandler={successHandler}
    >
      <ChangePasswordFormContent />
    </FormHandler>
  );
}
