import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";

const axios = require("axios");

function SupportReqestForm(props) {
  return (
    <Box component="form" onSubmit={props.handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="module"
        label="Subject"
        name="module"
        inputProps={{
          maxLength: 25
        }}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="description"
        label="Description"
        name="description"
        minRows="3"
        inputProps={{
          maxLength: 1000
        }}
        multiline
      />

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
    </Box>
  );
}

export default function (props) {
  const [message, setMessage] = React.useState(null,false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    let data = {};
    formData.forEach((value, key) => (data[key] = value));

    setMessage("Loading");
    console.log("Submitting form data:\n", data);
    axios
      .post("/api/user/support/newTicket", data)
      .then((resp) => {
        setMessage("Thank you, our support team will be in contact shortly.");
      })
      .catch((error) => {
        console.error("Error submitting form:\n", error);
        setMessage("Unfortunatly an error ocurred, please contact us by email");
      });
  };

  const content =
    message === null ? (
      <SupportReqestForm handleSubmit={handleSubmit} />
    ) : (
      <Typography>{message}</Typography>
    );

  return content;
}
