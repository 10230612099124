import {
  Box,
  Button,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import React from "react";

export function AnalysisReport(props) {
  return (
    <Container>
      <Typography>
        <strong> Generate an Analysis Report </strong>{" "}
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Click on the .anr file tab. </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/clickOnAnrTab.gif"}
            alt="Click on ANR tab"
            width="900"
            height="100%"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Click on the <strong> Create Analysis Report Icon</strong>, which
            will open the HTML Analysis Report Wizard.
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/generateReport.gif"}
            alt="HTML Analysis Report Icon"
            width="900"
            height="100%"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Configure the Run Analysis Wizard with the generated .anr file and
            the provided report template and click <strong> Finish </strong>
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/finishReport.gif"}
            alt="Finish Report Wizard"
            width="900"
            height="100%"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            On completion, the HTML report will open on your default web browser
            for analysis.
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/analysisReport.png"}
            alt="Finish Report Wizard"
            width="900"
            height="100%"
          />
        }
      </List>
    </Container>
  );
}
