import {
  Box,
  Button,
  Container,
  LinearProgress,
  Link as WWWLink,
  List,
  ListItem,
  ListItemText,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { Generate } from "./Generate";
import { TestExecutionSet } from "./TestExecutionSet";
import { AnalysisGeneration } from "./AnalysisGeneration";
import { AnalysisReport } from "./AnalysisReport";

function Homepage(props) {
  return (
    <Container>
      <Typography>
        <strong>
          {" "}
          Welcome to the quick start guide on running fuzz tests with
          ProtoCrawler.{" "}
        </strong>
      </Typography>
      <br></br>
      <Typography>
        This is a step-by-step tutorial on how to generate test execution sets,
        run fuzz tests and then analyse the results.
      </Typography>
      <br></br>

      <ListItemText>
        Let's first look at the end-to-end fuzz testing process:
      </ListItemText>
      {
        <img
          style={{ padding: "20px" }}
          src={"/quickstart/E2E.png"}
          alt="End-to-End process"
          width="750"
          height="100%"
        />
      }

      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            As can be seen from the diagram above, you will be using several
            different file types during the fuzz testing process.{" "}
          </ListItemText>
          <ListItemText> </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/termTable.png"}
            alt="Term Table"
            width="650"
            height="100%"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Now, let's look at the files that you have been provided with, which
            should be visible in the file explorer on the left of your screen:{" "}
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/filesProvided.png"}
            alt="Files Provided"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            When fuzz testing, you will generate and use these three file types:
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/filesGenerated.png"}
            alt="Files Generated"
          />
        }
      </List>
    </Container>
  );
}

const steps = [
  "Overview",
  "Generate a Test Case Group",
  "Generate and Run a Test Execution Set",
  "Generate and Run an Analysis",
  "Generate an Analysis Report"
];

function SetupWizard() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const getComponentForStep = (step) => {
    switch (step) {
      case 0:
        return <Homepage />;
      case 1:
        return <Generate />;
      case 2:
        return <TestExecutionSet />;
      case 3:
        return <AnalysisGeneration />;
      case 4:
        return <AnalysisReport />;
      default:
        return <Homepage />;
    }
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 5 }}>{getComponentForStep(activeStep)}</Box>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 1, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  ></Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? "Finish"
                      : "Complete Step"}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}

export default function (props) {
  return <SetupWizard />;
}
