import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";

const axios = require("axios");

function ApplicationForm(props) {
  return (
    <Box component="form" onSubmit={props.handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        autoComplete="name"
        inputProps={{
          maxLength: 25
        }}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="org"
        label="Company"
        name="company"
        inputProps={{
          maxLength: 25
        }}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        inputProps={{
          maxLength: 25
        }}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="description"
        label="Description"
        name="description"
        minRows="3"
        inputProps={{
          maxLength: 1000
        }}
        multiline
      />

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
    </Box>
  );
}

export default function (props) {
  const [message, setMessage] = React.useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    let data = {};
    formData.forEach((value, key) => (data[key] = value));

    setMessage("Loading");
    console.log("Submitting form data:\n", data);
    axios
      .post("/api/public/requestAccess", data)
      .then((resp) => {
        setMessage("Thank you for your interest, our sales team will be in touch!");
      })
      .catch((error) => {
        console.error("Error submitting form:\n", error);
        setMessage("Unfortunatly an error ocurred, please contact us by email");
      });
  };

  const content =
    message === null ? (
      <ApplicationForm handleSubmit={handleSubmit} />
    ) : (
      <Typography>{message}</Typography>
    );

  return content;
}
