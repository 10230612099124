
import { useNavigate, useParams, Link,useLocation } from "react-router-dom";


import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";


import {
  LoadDataContainer,
  FormHandler,
  TransferList
} from "../../components/data";


import {ChangePasswordFormContent } from  "../user/AccountDetails"


export function AdministerUserPage(props) {
  const { id } = useParams();
  const url = "/api/admin/user/" + id;


  const prepCustomerData = (d) => {
    const d2 = {...d}
    console.log("d2",d2)
    if(typeof d2.licenseTags === 'string')
    {
      d2.licenseTags = [d2.licenseTags.split(',')]
    }

    console.log("d22",d2)
    return d2
  }

  return (
    <Box>
    <LoadDataContainer getURL={url}>
      <Button component={Link} to={"/admin/user/" + id + "/adminPurchase"}>
        Add Purchase
      </Button>
      <Button component={Link} to={"/admin/user/" + id + "/sendWelcome"}>
        Send email
      </Button>
      <Button component={Link} to={"/admin/user/" + id + "/getCookie"}>
        Get Cookie
      </Button>
      <FormHandler postURL={url}>
        <EditUserDetailsFormContent />
      </FormHandler>

    </LoadDataContainer>
    <FormHandler postURL={url + "/updatePassword"}>
      <ChangePasswordFormContent />
    </FormHandler>

    <LoadDataContainer getURL={"/api/customer/admin/account/"+id}>
      <FormHandler postURL={"/api/customer/admin/account/"+id} prepData={prepCustomerData} >
        <CustomerAccountChangeFrom />
      </FormHandler>
      <UserPurchaseTable />
    </LoadDataContainer>

    </Box>
  );
}


function CustomerAccountChangeFrom(props) {
  console.log("CustomerAccountChangeFrom props.data",props)


  const setRight = (values) => {
    let d = { ...props.data };
    d.licenseTags = values;
    console.log("CustomerAccountChangeFrom props.data",d)
    props.setData(d);
  };
  const allTags = ["TRIAL","CODEMETER","CYTAL_DEVELOPER"]
  const availableTags = allTags.filter( (item) => !props.data.licenseTags.includes(item)) 

  return (
      <Box component="form" sx={{ mt: 3 }} onSubmit={props.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="cookieLifetime"
              required
              fullWidth
              id="cookieLifetime"
              label="Cookie Lifetime"
              autoFocus
              defaultValue={props?.data?.cookieLifetime}
            />
          </Grid>
          <Grid item xs={12}>
            <TransferList
            right={props.data.licenseTags}
            setRight={setRight}
            left={availableTags}
          />
          </Grid>
        </Grid>

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Update
        </Button>
        </Box>
        )
}


export function EditUserDetailsFormContent(props) {
  const setRight = (values) => {
    let d = { ...props.data };
    d.permissions = values;
    props.setData(d);
  };
  const setLeft = (values) => {};

  const availablePermissions = []
  /*props.data.assignablePermissions.filter(
    (perm) => !props.data.permissions.includes(perm)
  );*/
  return (
    <Box component="form" sx={{ mt: 3 }} onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            defaultValue={props?.data?.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            defaultValue={props?.data?.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            defaultValue={props?.data?.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TransferList
            right={props.data.permissions}
            setRight={setRight}
            left={availablePermissions}
          />
        </Grid>
      </Grid>

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Update
      </Button>
      </Box>
  )
}

export function AdminPurchasePage(props) {
  const { id,pid } = useParams();
  const location = useLocation();
  if(pid == undefined)
  {

    const url = "/api/customer/admin/account/"+id+"/adminPurchase"

    const from = new Date();
    const to = new Date();
    to.setMonth(from.getMonth() +1);

    const defaultData = {
      payment:"INVOICE",
      subscription:"MONTHLY",
      scope:"USER",
      renewalInterval:"MONTHLY",
      purchaseStatus:"NEW",
      validFrom:from.toISOString(),
      validTo:to.toISOString()
    }
    return (
      <Box>
        <FormHandler  data={defaultData} postURL={url}>
          <EditPurchaseDetails />
        </FormHandler>
      </Box>)
    }
  else {
    const url = "/api/customer/admin/purchase/"+pid
    return (
      <Box>
        <LoadDataContainer getURL={url}>
          <FormHandler postURL={url}>
            <EditPurchaseDetails />
          </FormHandler>
        </LoadDataContainer>
      </Box>)
  }
}


export function SendWelcomePage(props) {
  const { id } = useParams();

    const url = "/api/customer/admin/account/"+id+"/sendWelcomeEmail"

    const defaultData = {
      sendTo:""
    }
    return (
      <Box>
        <FormHandler  data={defaultData} postURL={url}>
          <SendWelcomeForm />
        </FormHandler>
      </Box>)
}




function SendWelcomeForm(props) {
  return (
    <Box component="form" sx={{ mt: 3 }} onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="sendTo"
            label="Send To Alternative"
            name="sendTo"
            defaultValue={props?.data?.sendTo}
          />
        </Grid>
      </Grid>

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Send
      </Button>
    </Box>
  );
}

export function GetCookie(props) {
  const { id } = useParams();

    const url = "/api/customer/admin/account/"+id+"/requestCookie"

    const defaultData = {
      validityHrs:24,
      machineId:""

    }
    return (
      <Box>
        <FormHandler  data={defaultData} postURL={url}>
          <GetCookieForm />
        </FormHandler>
      </Box>)
}

function GetCookieForm(props) {

  return (
    <Box component="form" sx={{ mt: 3 }} onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="machineId"
            label="Machine Id"
            name="machineId"
            defaultValue={props?.data?.machineId}
          />
        </Grid>
          <TextField
            fullWidth
            id="validityHrs"
            label="Cookie lifetime"
            name="validityHrs"
            defaultValue={props?.data?.validityHrs}
          />
        </Grid>
      </Grid>

      <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        TODO
      </Button>
    </Box>
  );
}




function EditPurchaseDetails(props) {
  console.debug("EditPurchaseDetails",props)

  return (
    <Box component="form" sx={{ mt: 3 }} onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="bundleId"
            required
            fullWidth
            id="bundleId"
            label="Bundle ID"
            defaultValue={props?.data?.bundleId}
          />
        </Grid>


        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="purchaseStatus"
            label="Purchase Status"
            name="purchaseStatus"
            defaultValue={props?.data?.purchaseStatus}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="payment"
            label="Payment"
            name="payment"
            defaultValue={props?.data?.payment}
          />
          </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="renewalInterval"
            label="Renewal Interval"
            name="renewalInterval"
            defaultValue={props?.data?.renewalInterval}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="requestedPrice"
            label="Requested Price"
            name="requestedPrice"
            defaultValue={props?.data?.requestedPrice}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="scope"
            label="Scope"
            name="scope"
            autoComplete="email"
            defaultValue={props?.data?.scope}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="subscription"
            label="Subscription"
            name="subscription"
            autoComplete="email"
            defaultValue={props?.data?.subscription}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="validFrom"
            label="Valid From"
            name="validFrom"
            defaultValue={props?.data?.validFrom}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="validTo"
            label="Valid To"
            name="validTo"
            defaultValue={props?.data?.validTo}
          />
        </Grid>
      </Grid>

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Update
      </Button>
    </Box>
  );
}


function EditAccountDetailsFormContent(props) {
  console.log("EditUserDetailsFormContent.props", props);
  return (
    <Box component="form" sx={{ mt: 3 }} onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            defaultValue={props?.data?.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            defaultValue={props?.data?.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            defaultValue={props?.data?.email}
          />
        </Grid>
      </Grid>

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Update
      </Button>
    </Box>
  );
}


function getBundleName(params)
{
  return params.row.bundle.name;
}

export function UserPurchaseTable(props) {
  console.debug("UserPurchaseTable ",props.data.bundles)

  const columns = [
    {
      field: "id",
      headerName: "ID",
      renderCell: (p) => <Link to={"/admin/purchase/" + p.value}>{p.value} </Link>,
    },
    {
      field: "bundleName",
      headerName: "Bundle",
      valueGetter: getBundleName,
      flex:0.10,
    },
    {
      field: "paymentOption",
      flex:0.10,
      headerName: "Payment Option"
    },
    {
      field: "purchasePrice",
      flex:0.10,
      headerName: "Purchase Price"
    },
    {
      field: "purchaseStatus",
      flex:0.10,
      headerName: "Purchase Status"
    },
    {
      field: "renewalInterval",
      flex:0.10,
      headerName: "Renewal Interval"
    },
    {
      field: "validFrom",
      flex:0.10,
      headerName: "Valid From"
    },
    {
      field: "validTo",
      flex:0.10,
      headerName: "Valid To"
    },
  ];
  const rows = props.data.bundles;

  return (
    <div style={{ height: 400 }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}
