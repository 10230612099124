import React from "react";
import { useUserService } from "./../../../UserServiceProvider";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { Card, CardActions, CardContent, Grid } from "@mui/material";

import { MarketBundleCardHeader } from "../../../components/BundleDetails";

import { LoadMultiDataContainer } from "../../../components/data";

export function MarketPage(props) {

    const { userDetails,isUserAdmin,hasPermission } = useUserService();
  const requests = [
    {
      name: "myBundles",
      url: "/api/market/store/myBundles"
    },
    {
      name: "availableBundles",
      url: "/api/market/store/availableBundles"
    }
  ];

  if(!hasPermission("USER_PURCHASE"))
    return (
      <p>Unfortunately you dont have permission to make purchases from the catalogue.  Please contact your organisation manager or customer support. </p> 
    )


  return (
    <LoadMultiDataContainer requests={requests}>
      <MarketPageContent />
    </LoadMultiDataContainer>
  );
}

function MarketPageContent(props) {
  return <CatalogueGrid bundles={props.data.availableBundles} />;
}
function MyBundlesTable(props) {
  if (props.myModules == null) {
    return <p>Loading...</p>;
  }

  const entries = props.myModules.map((purchase) => {
    return (
      <tr key={purchase.id}>
        <td>{purchase.bundle.name}</td>
        <td>{purchase.bundle.description}</td>
      </tr>
    );
  });

  return (
    <table>
      <tbody>{entries}</tbody>
    </table>
  );
}

function CatalogueGrid(props) {

  const navigate = useNavigate();
  const handlePurchase = function handlePurchase(bundle) {
    console.log("Purchasing bundle", bundle);
    navigate("purchase", { state: { bundle: bundle } });
  };

  const entries = props.bundles.map((bundle) => (
    <Grid item bundle={bundle} xs={12} sm={6} md={4}>
      <Card style={{ height: "100%" }}>
        <CardContent>
          <MarketBundleCardHeader bundle={bundle} />
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={() => handlePurchase(bundle)} >
            Purchase
          </Button>
        </CardActions>
      </Card>
    </Grid>
  ));

  return (
    <Grid container spacing={5}>
      {entries}
    </Grid>
  );
}

function AvailableBundlesTable(props) {
  const { apiClient } = useUserService();
  const navigate = useNavigate();

  if (props.bundles == null) {
    return <p>Loading...</p>;
  }

  const handlePurchase = function handlePurchase(bundle) {
    console.log("Purchasing bundle", bundle);
    navigate("purchase", { state: { bundle: bundle } });
    /*apiClient
      .post("/api/market/store/purchase", bundle)
      .then((resp) => {
        console.log("Purchase success", resp);
        props.setMessage("Purchase success");
        props.loadData();
      })
      .catch((error) => {
        props.setMessage("Purchase failed");
      });*/
  };

  const entries = props.bundles.map((bundle) => {
    return (
      <tr key={bundle.id}>
        <td>{bundle.name}</td>
        <td>{bundle.description}</td>
        <td>
          <Button onClick={() => handlePurchase(bundle)}>Purchase</Button>
        </td>
      </tr>
    );
  });
  return (
    <table>
      <tbody>{entries}</tbody>
    </table>
  );
}
