import {
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Typography
} from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useUserService } from "../../../UserServiceProvider";

import {
  MarketBundleCardContent,
  MarketBundleContentPane
} from "../../../components/BundleDetails";
import { LoadDataContainer } from "../../../components/data";


function BundlePurchaseCard(props) {
  const { apiClient } = useUserService();
  const navigate = useNavigate();

  const handleCancel = () => {
    apiClient
      .get("/api/market/store/cancel", {
        params: { purchaseId: props.purchase.id }
      })
      .then((resp) => {
        navigate(-1);
      });
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <MarketBundleCardContent bundle={props.data.bundle} />
      <CardActions>
        <Button onClick={() => navigate(-1)}> back </Button>
        <Button onClick={handleCancel}> Cancel Subscription</Button>
      </CardActions>
    </Card>
  );
}

export default function (props) {
  const { id } = useParams();


  return (
    <LoadDataContainer
      getURL={"/api/market/store/" + props.function + "/" + id}
    >
      <BundlePurchaseCard />
    </LoadDataContainer>
  );
}
