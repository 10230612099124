import * as React from "react";

import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import {
  LoadDataContainer,
  FormHandler,
  TransferList
} from "../../components/data";


import {UserPurchaseTable} from './useradministration'


function EditOrganisationForm(props) {
  const setRight = (values) => {
    let d = { ...props.data };
    console.log("Set right permissions", values);
    d.assignablePermissions = values;
    props.setData(d);
  };
  const setLeft = (values) => {
    console.log("Set left permissions", values);
  };
  return (
    <Box component="form" onSubmit={props.handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        inputProps={{
          maxLength: 25
        }}
        defaultValue={props?.data?.name}
        autoFocus
      />
      <Box sx={{ display: "flex" }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="monthly"
          label="Monthly Discount"
          name="pricePlan.monthly"
          inputProps={{
            maxLength: 25
          }}
          defaultValue={props?.data?.pricePlan.monthly}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="quarterly"
          label="Quarterly Discount"
          name="pricePlan.quarterly"
          inputProps={{
            maxLength: 25
          }}
          defaultValue={props?.data?.pricePlan.quarterly}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="annually"
          label="Annual Discount"
          name="pricePlan.annually"
          inputProps={{
            maxLength: 25
          }}
          defaultValue={props?.data?.pricePlan.annually}
        />
      </Box>

      <TransferList
        right={props.data.assignablePermissions}
        setRight={setRight}
        left={[]}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
    </Box>
  );
}

export function EditOrganisationPage(props) {
  const { id } = useParams();
  const url = "/api/customer/admin/" + id;
  return (
    <LoadDataContainer getURL={url}>
      <Button component={Link} to={"/admin/organisation/" + id + "/createUser"}>
        Create User
      </Button>
      <FormHandler postURL={url}>
        <EditOrganisationForm />
      </FormHandler>

      <UserEntityTable />

      <OrganistionPurchaseTable  />
    </LoadDataContainer>
  );
}

export function ListOrganisationsPage(props) {
  return (
    <Box>
      <Button component={Link} to="./create">
        New
      </Button>
      <LoadDataContainer getURL="/api/customer/admin/list">
        <EntityTable />
      </LoadDataContainer>
    </Box>
  );
}

function EntityTable(props) {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      renderCell: (p) => <Link to={"./" + p.value}>{p.value} </Link>
    },
    {
      field: "name",
      headerName: "Name"
    }
  ];
  const rows = props.data;
  console.log("Data table", props.data);

  return (
    <div style={{ height: 400 }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}

export function CreateOrganisationPage(props) {
  const navigate = useNavigate();
  const successHandler = (data, setMessage) => {
    navigate("../" + data.id, {
      state: { message: { severity: "success", text: "Organisation Created" } }
    });
  };

  return (
    <LoadDataContainer getURL="/api/customer/admin/defaults">
      <FormHandler
        successHandler={successHandler}
        postURL="/api/customer/admin/createOrganisation"
      >
        <EditOrganisationForm />
      </FormHandler>

    </LoadDataContainer>
  );
}

function CreateUserForm(props) {
  const setRight = (values) => {
    let d = { ...props.data };
    d.permissions = values;
    props.setData(d);
  };
  const setLeft = (values) => {};

  const availablePermissions = props.data.assignablePermissions.filter(
    (perm) => !props.data.permissions.includes(perm)
  );
  return (
    <Box component="form" sx={{ mt: 3 }} onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox value="allowExtraEmails" color="primary" />}
            label="I want to receive inspiration, marketing promotions and updates via email."
          />
        </Grid>

        <Grid item xs={12}>
          <TransferList
            right={props.data.permissions}
            setRight={setRight}
            left={availablePermissions}
          />
        </Grid>
      </Grid>

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Create User
      </Button>
    </Box>
  );
}

export function CreateOrganisationUserPage(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const apiBase = "/api/customer/admin/" + id;
  const successHandler = (data, setMessage) => {
    navigate("../", {
      state: { message: { severity: "success", text: "User Created" } }
    });
  };

  return (
    <LoadDataContainer getURL={apiBase} defaultData={{ permissions: [] }}>
      <FormHandler
        successHandler={successHandler}
        postURL={apiBase + "/create"}
      >
        <CreateUserForm />
      </FormHandler>
    </LoadDataContainer>
  );
}

function UserEntityTable(props) {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      renderCell: (p) => <Link to={"/admin/user/" + p.value}>{p.value} </Link>
    },
    {
      field: "email",
      headerName: "Email"
    },
    {
      field: "firstName",
      headerName: "First Name"
    },
    {
      field: "lastName",
      headerName: "Last Name"
    }
  ];
  const rows = props.data.users;
  console.log("Users Table", props.data.users);

  return (
    <div style={{ height: 400 }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}


function OrganistionPurchaseTable(props)
{
  return (
       <UserPurchaseTable {...props} />
    )
}