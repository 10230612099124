

import Box from "@mui/material/Box";
import AdbIcon from "@mui/icons-material/Adb";
import largeImage from "./protocrawler_large.png"

export function ProtoCrawlerLogo(props) {

    return (
      <Box {...props}>
        <img height="20px" src={largeImage} alt="ProtoCrawler Logo" />
        </Box>
    )
    /*return (
      <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />)*/
}
