import * as React from 'react'

import { Link, useNavigate, useParams,useLocation } from "react-router-dom";



import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import { DataGrid } from "@mui/x-data-grid";


import { useUserService } from "../../UserServiceProvider";


import {
  FormHandler,
  LoadDataContainer,
  LoadMultiDataContainer,
  TransferList
} from "../../components/data";

function UploadFileForm(props)
{
  const { apiClient } = useUserService();
  const [file,setFile] =  React.useState();
  const navigate = useNavigate();

  var handleFormSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target);
    console.log("Uploading file",file)
    formData.append('file',file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    apiClient.post("/api/admin/files/upload",formData,config).then(resp=> {
      navigate("../")
    }
    )
  }

  var onFileChange = (e) => {
    console.log("Selecting file ",e.target.files[0])
    setFile(e.target.files[0])
  }

  return (<Box component="form" onSubmit={handleFormSubmit} >
    <h1>Upload Files </h1>

    <TextField
      required
      id="path"
      name="path"
      label="Path"
      defaultValue="/"
    />

    <TextField
      required
      id="description"
      name="description"
      label="Description"
    />
      <Checkbox name="overwrite" id="overwrite" defaultChecked />
    <input
  accept="image/*"
  id="raised-button-file"
  multiple
  type="file"
  onChange={onFileChange}
/>
    <Button type="submit">Upload</Button>
    </Box>)
}

function FilesTable(props) {
  const columns = [

    {
      field: "name",
      headerName: "Name",
      flex: 2
    },

    {
      field: "description",
      headerName: "Description",
      flex: 4
    },



    {
      field: "modifiedTime",
      headerName: "Modified",
      flex: 4
    },

    {
      field: "id",
      headerName: "Path",
      renderCell: (p) => <Link to={"./info/" + p.value}  state={{id:p.value}} >{p.value} </Link>,
      flex: 6
    },
  ];
  const rows = props.data;

  return (
    <div style={{ height: 400 }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}

export function UploadFilesPage(props)
{
  return (

    <UploadFileForm />
  )
}

function FileEditForm(props) {
  const { apiClient } = useUserService();
  const navigate = useNavigate();
  console.log("FileEditForm.props",props)

  function handleDelete() {
    const url =  "/api/admin/files/delete"+props.data.id
    apiClient.delete(url).then(resp => {
      navigate("/admin/files")
    } )
  }

  return (
      <Box component="form"   onSubmit={props.handleSubmit}>
      <h1>Edit File </h1>

      <TextField
        required
        id="path"
        name="path"
        label="Path"
        defaultValue={props.data.id}
      />

      <TextField
        required
        id="name"
        name="name"
        label="Name"
        defaultValue={props.data.name}
      />

      <TextField
        required
        id="description"
        name="description"
        label="Description"
        defaultValue={props.data.description}
      />

      <Button type="submit">Save</Button>
      <Button onClick={handleDelete}> Delete </Button>
      </Box>

  )
}

export function EditFilePage(props) {

  const params = useParams();
  const url =  "/api/admin/files/info"+params["*"]
  console.log("EditFile Requesting",url);
  return (
    <LoadDataContainer getURL={url}>
      <FormHandler
        postURL={url}
      >
        <FileEditForm />
      </FormHandler>
    </LoadDataContainer>
  );
}

export function FilesPage(props) {
    const { apiClient } = useUserService();
  return (

    <Box>
      <Button component={Link} to="./upload">
        Upload
      </Button>

      <Button onClick={()=>{
        apiClient.post("/api/admin/files/scanForFiles").then(resp => {
          window.location.reload()
        })
      }}>
        Scan
      </Button>

      <h1>Missing Files</h1>
      <LoadDataContainer getURL="/api/admin/files/list/missing">
        <FilesTable />
      </LoadDataContainer>

      <h1> Files </h1>
      <LoadDataContainer getURL="/api/admin/files/list">
        <FilesTable />
      </LoadDataContainer>
    </Box>
  )
}
