import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useNavigate } from "react-router-dom";

//import { ModulesTable } from "./modules";
import { useUserService } from "../../UserServiceProvider";
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import { LoadMultiDataContainer } from "../../components/data";

const theme = createTheme();
/*
export class Bundles extends React.Component {
  constructor(props) {
    super(props);
    this.state = { marketBundles: null };
  }

  componentDidMount() {
    //FIXME Should not use this directly
    const { apiClient } = useUserService();
    apiClient.get("/api/marketBundles").then((resp) => {
      console.log(resp.data._embedded.marketBundles);
      this.setState(resp.data._embedded);
    });
  }
  render() {
    if (this.state.marketBundles == null) {
      return <p>Loading...</p>;
    }

    const entries = this.state.marketBundles.map((bundle) => (
      <MarketBundleRow key={bundle._links.self.href} bundle={bundle} />
    ));

    return <div>{entries}</div>;
  }
}*/

function MyBundlesContent(props) {
  return (
    <Container>
      <Typography variant="h5"> My Bundles </Typography>
      <SimpleBundleTable prefix="purchased" bundles={props.data.myBundles} />
      <Typography variant="h5"> Organisation Bundles </Typography>
      <SimpleBundleTable
        prefix="organisationPurchased"
        bundles={props.data.orgBundles}
      />
    </Container>
  );
}

export function MyBundlesPage(props) {
  const requests = [
    {
      name: "myBundles",
      url: "/api/market/store/myBundles"
    },
    {
      name: "orgBundles",
      url: "/api/market/store/organisationBundles"
    }
  ];
  return (
    <LoadMultiDataContainer requests={requests}>
      <MyBundlesContent />
    </LoadMultiDataContainer>
  );
}

function SimpleBundleTable(props) {
  const rows = props.bundles.map((purchase) => (
    <TableRow key={purchase.bundle.name}>
      <TableCell>
        <Link
          to={"/user/market/" + props.prefix + "/" + purchase.id + "/details"}
        >
          {purchase.bundle.name}
        </Link>
      </TableCell>
      <TableCell>{purchase.bundle.description}</TableCell>
    </TableRow>
  ));
  return (
    <Table>
      <TableBody>{rows}</TableBody>
    </Table>
  );
}
/*
class MarketBundleRow extends React.Component {
  render() {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={this.props.key + "-content"}
          id={this.props.key + "-header"}
        >
          <Typography>{this.props.bundle.name}</Typography>
          <Typography>{this.props.bundle.description}</Typography>
        </AccordionSummary>
        <MarketBundleModules bundle={this.props.bundle} />
      </Accordion>
    );
  }
}*/

/*
function MarketBundleModules(props) {
  const { apiClient } = useUserService();
  const [data, setData] = React.useState(null);
  const [message, setMessage] = React.useState("Loading...");
  React.useEffect(() => {
    apiClient
      .get(new URL(props.bundle._links.modules.href).pathname)
      .then((resp) => {
        console.log("Got bundle modules", resp.data._embedded.moduleEntries);
        setData(resp.data._embedded.moduleEntries);
        setMessage(null);
      });
  }, []);

  if (message != null) {
    return <AccordionDetails>{message}</AccordionDetails>;
  }

  return (
    <AccordionDetails>
      <ModulesTable moduleEntries={data} />
    </AccordionDetails>
  );
}*/

export const CreateBundlePage = (props) => {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState(null);
  const [features, setFeatures] = React.useState([]);
  const { apiClient } = useUserService();

  const handleSubmit = (event) => {
    event.preventDefault();
    const inputs = new FormData(event.currentTarget);

    const data = {
      name: inputs.get("name"),
      description: inputs.get("description"),
      basePrice: inputs.get("basePrice"),
      features: features
    };

    apiClient
      .post("/api/marketplace/management/createBundle", data)
      .then((resp) => {
        console.log("Create bunlde success");
        console.log(resp);
        navigate("../");
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setMessage(error.response.data.message);
      });
  };

  handleSubmit.bind(this);

  const errorMessage = message;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <CssBaseline />
        <Box>
          <Typography component="h1" variant="h5">
            Create Module
          </Typography>

          {message && <Alert severity="error">{errorMessage}</Alert>}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="name"
              id="name"
              label="Bundle Name"
              required
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="description"
              id="description"
              required
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="basePrice"
              id="basePrice"
              required
            />

            <TransferList right={features} setRight={setFeatures} />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function TransferList(props) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(["hello", "world"]);
  const [filterText, setFileterText] = React.useState();
  const { apiClient } = useUserService();
  //const [right, setRight] = React.useState([4, 5, 6, 7]);

  React.useEffect(() => {
    apiClient.get("/api/featureItems").then((resp) => {
      console.log("Go featureItems response");
      console.log(resp.data._embedded.featureItems);
      const hrefs = resp.data._embedded.featureItems.map(
        (mod) => mod._links.self.href
      );
      const ids = hrefs.map((href) => href.slice(href.lastIndexOf("/") + 1));
      setLeft(ids);
    });
  }, []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, props.right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    props.setRight(props.right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    props.setRight(not(props.right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected"
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto"
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const handleSearchChange = function (e) {
    console.log("Setting filter text ", e.target.value);
    setFileterText(e.target.value);
  };

  let filteredLeft;

  if (filterText) filteredLeft = left.filter((id) => id.includes(filterText));
  else {
    filteredLeft = left;
  }
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <TextField onChange={handleSearchChange}> </TextField>
          {customList("Choices", filteredLeft)}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Chosen", props.right)}</Grid>
    </Grid>
  );
}
