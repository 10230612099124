import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField
} from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";

import { useUserService } from "../UserServiceProvider";

function setSubField(root, path, value) {
  let obj = root;
  let parts = path.split(".");

  for (var i = 0; i < parts.length - 1; i++) {
    if (!obj[parts[i]]) {
      obj[parts[i]] = {};
    }
    obj = obj[parts[i]];
  }

  obj[parts[parts.length - 1]] = value;
}

export function FormHandler(props) {
  const location = useLocation();
  const { apiClient } = useUserService();
  const [message, setMessage] = React.useState(
    location.state?.message ? location.state.message : null
  );

  const prepData = props.prepData ? props.prepData : () => ({ ...props.data });

  const successHandler = props.successHandler
    ? props.successHandler
    : () => {
        setMessage({ severity: "success", text: "Changes saved" });
      };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    let data = prepData(props.data);
    formData.forEach((value, key) => setSubField(data, key, value));
    console.log("Submitting form data:\n", data);
    apiClient
      .post(props.postURL, data)
      .then((resp) => {
        console.log("Got form response:\n", resp);
        successHandler(resp.data, setMessage);
      })
      .catch((error) => {
        console.error("Error submitting form:\n", error);

        setMessage({ severity: "error", text: error.message });
      });
  };
  const data = props.data;
  const setData = props.setData;
  console.debug("Form Handler props", data);
  const children = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { handleSubmit, data, setData });
    }
    return child;
  });
  return (
    <React.Fragment>
      {message && <Alert severity={message.severity}>{message.text}</Alert>}
      {children}
    </React.Fragment>
  );
}

export function LoadDataContainer(props) {
  const { apiClient } = useUserService();
  const [data, setData] = React.useState(null);

  const [message, setMessage] = React.useState(null);
  const requestConfig = props.requestConfig ? props.requestConfig : {};
  if (!props.getURL) throw new Error("getURL not passed to LoadDataContainer");

  React.useEffect(() => {
    console.debug("Issuing get request for data ", props.getURL);
    apiClient
      .get(props.getURL, requestConfig)
      .then((resp) => {
        setMessage(null);

        let d;
        //FIXME This assumes object as data root, needs enchanced if deault array content is ever needed
        if (props.defaultData) d = { ...props.defaultData, ...resp.data };
        else d = resp.data;
        console.log("Loaded data from '" + props.getURL + "':\n", d);
        setData(d);
      })
      .catch((error) => {
        console.error(
          "Error loading data from '" + props.getURL + "':\n",
          error
        );
        setMessage(error.data);
      });
  }, []);

  let content;
  if (data) {
    content = React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { data, setData });
      }
      return child;
    });
  } else content = <LinearProgress />;

  return (
    <React.Fragment>
      {message && <Alert severity="error">{message}</Alert>}
      {content}
    </React.Fragment>
  );
}

export function LoadMultiDataContainer(props) {
  const { apiClient } = useUserService();
  const [data, setData] = React.useState(null);

  const [message, setMessage] = React.useState(null);

  if (!props.requests)
    throw new Error("requests not passed to LoadMultiDataContainer");

  React.useEffect(() => {
    console.debug("Issuing get requests for data ", props.requests);

    const getRequests = props.requests.filter((r) => r.url !== undefined);

    Promise.all(getRequests.map((r) => apiClient.get(r.url)))
      .then((responses) => {
        setMessage(null);

        let d = {};

        props.requests
          .filter((r) => r.value !== undefined)
          .forEach((r) => (d[r.name] = r.value));
        console.log("Data after defaults", d);
        for (let i = 0; i < responses.length; i++) {
          console.debug(
            "Read data item",
            getRequests[i].name,
            getRequests[i].url,
            responses[i].data
          );
          d[getRequests[i].name] = responses[i].data;
        }
        console.log("Loaded data multi data:\n", d);
        setData(d);
      })
      .catch((error) => {
        console.error(
          "Error loading data from '" + props.getURL + "':\n",
          error
        );
        setMessage(error.data);
      });
  }, []);

  let content;
  if (data) {
    content = React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { data, setData });
      }
      return child;
    });
  } else content = <LinearProgress />;

  return (
    <React.Fragment>
      {message && <Alert severity="error">{message}</Alert>}
      {content}
    </React.Fragment>
  );
}

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export function TransferList(props) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(props.left);
  const right = props.right;
  const setRight = props.setRight;
  const [filterText, setFileterText] = React.useState();

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const getLabel =
    props.getLabel !== undefined
      ? props.getLabel
      : (a) => {
          return a;
        };

  console.log("asdf", toString("asdf"));

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(props.right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(props.right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  for (let i in left) {
    //console.log(left[i]);
    // console.error(toString("left[i]"));
  }
  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected"
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto"
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${getLabel(value)}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const handleSearchChange = function (e) {
    console.log("Setting filter text ", e.target.value);
    setFileterText(e.target.value);
  };

  let filteredLeft;
  let filteredRight;

  if (filterText) {
    filteredLeft = left.filter((id) => getLabel(id).includes(filterText));
    filteredRight = right.filter((id) => getLabel(id).includes(filterText));
  } else {
    filteredLeft = left;
    filteredRight = right;
  }
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid xs={12} item>
        <TextField onChange={handleSearchChange}> </TextField>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          {customList("Choices", filteredLeft)}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Chosen", filteredRight)}</Grid>
    </Grid>
  );
}
