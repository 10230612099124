import * as React from "react";
import { Navigate } from "react-router-dom";
export function Elavate(props) {
  console.debug("Elavating");
  return <Navigate to="/admin/" />;
}

export function Drop(props) {
  return <Navigate to="/user/" />;
}
