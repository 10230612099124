import {
  Box,
  Button,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import React from "react";

export function TestExecutionSet(props) {
  return (
    <Container>
      <Typography>
        <strong>Generating and running a Test Execution Set</strong>
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Click on Test Suite <em> 'Protocol Name'</em> in the file explorer.
          </ListItemText>
          {
            <img
              style={{ padding: "20px" }}
              src={"/quickstart/clickModbusTestSuite.gif"}
              alt="Click on TestSuite file"
              width="900"
              height="100%"
            />
          }
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Click on the <strong> Generate Icon </strong> on the task bar and
            configure a Test Execution Set (.tes).
          </ListItemText>
          {
            <img
              style={{ padding: "20px" }}
              src={"/quickstart/clickGenerate.gif"}
              alt="Click on TestSuite file"
              width="900"
              height="100%"
            />
          }
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Configure the Execution Set Wizard and click on{" "}
            <strong> Finish </strong> to produce the .tes file
          </ListItemText>
          {
            <img
              style={{ padding: "20px" }}
              src={"/quickstart/clickFinishTes.gif"}
              alt="Create TES"
              width="900"
              height="100%"
            />
          }
        </ListItem>

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            When the .tes file has opened, hit <strong> Run </strong> to run the
            Test Execution Set:
          </ListItemText>
          {
            <img
              style={{ padding: "20px" }}
              src={"/quickstart/runTes.gif"}
              alt="Run TES"
              width="900"
              height="100%"
            />
          }
        </ListItem>
      </List>
    </Container>
  );
}
