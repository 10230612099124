import * as React from "react";

import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import WWWLink from "@mui/material/Link";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function LandingPage() {
  return (
    <main>
      {/* Hero unit */}
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6
        }}
      >
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            ProtoCrawler<sup style={{"font-size":11}}> TM</sup>
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            Highly intelligent and automated fuzz testing to address your cyber
            security needs
          </Typography>
          <Typography variant="h6" color="text.secondary" paragraph>
            <List>
              <ListItem>
                <ListItemIcon>
                    <CheckBoxIcon
                    edge="start" />
                </ListItemIcon>
                <ListItemText>
                  Uncover bugs and unknown vulnerabilities in your products
                </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                  <CheckBoxIcon
                  edge="start" />
              </ListItemIcon>
              <ListItemText>
                Assess the overall robustness of software and devices
              </ListItemText>
            </ListItem>

          <ListItem>
            <ListItemIcon>
                <CheckBoxIcon
                edge="start" />
            </ListItemIcon>
            <ListItemText>
              Demonstrate the security of your solution
            </ListItemText>
          </ListItem>
            </List>
          </Typography>

          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button component={Link} to="/login" variant="contained">
              Login
            </Button>
            <Button
              component={Link}
              to="/public/requestAccess"
              variant="outlined"
            >
              Request Access
            </Button>
          </Stack>
        </Container>
      </Box>
    </main>
  );
}
