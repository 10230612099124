import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { apiClient, useUserService } from "../../UserServiceProvider";

const theme = createTheme();

export class ModulesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { moduleEntries: null };
  }

  componentDidMount() {
    apiClient.get("/api/moduleEntries").then((resp) => {
      console.log("Go modules response");
      console.log(resp.data._embedded.moduleEntries);
      this.setState(resp.data._embedded);
    });
  }
  render() {
    if (this.state.moduleEntries == null) {
      return <p>Loading...</p>;
    }

    return (
      <div>
        <Link to="../create"> Create</Link>
        <ModulesTable moduleEntries={this.state.moduleEntries} />
      </div>
    );
  }
}

export function ModulesTable(props) {
  const entries = props.moduleEntries.map((moduleEntry) => (
    <ModuleEntryRow
      key={moduleEntry._links.self.href}
      moduleEntry={moduleEntry}
    />
  ));

  return (
    <table>
      <tbody>{entries}</tbody>
    </table>
  );
}

class ModuleEntryRow extends React.Component {
  render() {
    return (
      <tr>
        <td>{this.props.moduleEntry.name}</td>
        <td>{this.props.moduleEntry.description}</td>
        <td>
          {this.props.moduleEntry._links.self.href.split("moduleEntries/")[1]}
        </td>
      </tr>
    );
  }
}

export const CreateModulePage = (props) => {
  const navigate = useNavigate();
  const [
    message,
    setMessage,
    id,
    setId,
    name,
    setName,
    description,
    setDescription
  ] = React.useState(null, null, "Test Name", "Test Description");
  const { apiClient } = useUserService();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    apiClient
      .post("/api/modules/create", {
        id: data.get("id"),
        name: data.get("name"),
        description: data.get("description")
      })
      .then((resp) => {
        console.log("Create module success");
        console.log(resp);
        navigate("../");
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setMessage(error.response.data.message);
      });
  };

  handleSubmit.bind(this);

  const errorMessage = message;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography component="h1" variant="h5">
            Create Module
          </Typography>

          {message && <Alert severity="error">{errorMessage}</Alert>}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="id"
              label="Id"
              name="id"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="name"
              id="name"
              value="Test Name"
              required
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="description"
              id="description"
              value="Test Description"
              required
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
