import {
  Box,
  Button,
  Container,
  LinearProgress,
  Link as WWWLink,
  List,
  ListItem,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { FilesTable } from "../../../components/BundleDetails";
import { LoadDataContainer } from "../../../components/data";
import { useUserService } from "../../../UserServiceProvider";
function DownloadInstructions(props) {
  return (
    <Container>
      <Typography>
        Installing ProtoCrawler is a simple download and extract process.
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Download the latest recommended release.</ListItemText>
          <FilesTable data={props.files} />
        </ListItem>

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Extract the file to your preferred installation location using your
            favourite extraction tool.
          </ListItemText>
          <List>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                The install location can be any file system directory to which
                you have read/write/execute permissions.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Note: If replacing an existing installation in the same location
                then we recommend deleting the old directory first. Extracting
                the new install on top of the old one can cause confusion with
                old plugin versions.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Note: On Windows we recommend choosing a directory in your user
                home or close to the root to avoid any issues with long path
                names.
              </ListItemText>
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            ProtoCrawler is now installed and ready to run.
          </ListItemText>
          <List>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Use the <i>'protocrawler' executable</i> in the installation location to
                launch the main GUI application.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                The bin directory in the installation location contains
                additional command line tools.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Optionally: Add the bin directory to your path to make running
                the command line tools easier.
              </ListItemText>
            </ListItem>
          </List>
        </ListItem>
      </List>
    </Container>
  );
}

function LoginAndWorkspace(props) {
  return (
    <Container>
      <Typography>
        The main ProtoCrawler application requires you to log in and select a
        workspace during launch.
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Open the ProtoCrawler application using the executable in your
            chosen install location.
          </ListItemText>
          <img style={{padding:"20px"}}  src={require('./open_protocrawler.gif')} alt="Open  protocrawler example" />
        </ListItem>

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Enter your account login details as used for this site.
          </ListItemText>
          <img style={{padding:"20px"}} src={require('./enter_login.gif')} alt="Enter login Details example" />
        </ListItem>

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Select a directory to use as the ProtoCrawler workspace.
          </ListItemText>
          <img style={{padding:"20px"}} src={require('./select_workspace.gif')} alt="Select workspace example" />
          <List>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                The workspace can be any file system directory to which you have
                read/write permissions.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                ProtoCrawler uses the workspace as its main working directory
                for displaying, saving and loading files. Many settings and
                preferences are also stored within the workspace.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                You can choose to have multiple workspaces to separate different
                projects' folders and settings.
              </ListItemText>
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Click OK and ProtoCrawler will log you in and open in the selected
            workspace.
          </ListItemText>
        </ListItem>
      </List>
    </Container>
  );
}

function ProtocolsAndFeatures(props) {
  return (
    <Container>
      <Typography>
        With the main application running you now need to install support for
        the protocols and additional features you want to use.
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Select 'Install new features' from the Application menu in the main window menu bar.
          </ListItemText>
          <img style={{padding:"20px"}} src={require('./open_install_wizard.gif')} alt="Open install wizard example" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Select to work with the ProtoCrawler install site (if not selected by default).
          </ListItemText>
          <img style={{padding:"20px"}} src={require('./select_update_site.gif')} alt="Select update site" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Use the install wizard to select and install the components you want
            to use.
          </ListItemText>
          <img style={{padding:"20px"}} src={require('./install_ocpp.gif')} alt="Install OCPP example" />
          <List>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                One option is to install all available features and updates. Just use the select all option and proceed.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Alternatively you can select to install individual items, you can view their details and required features in <Link to="/user/bundles">My Bundles</Link>.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                Complete the wizard and restart ProtoCrawler to complete the
                installation.
              </ListItemText>
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            See the <Link to="/user/market">Catalogue</Link> if you need
            additional protocols or support.
          </ListItemText>
        </ListItem>
      </List>
    </Container>
  );
}

function NextSteps(props) {
  return (
    <Container>
      <Typography>
        Congratulations, you are now ready to start testing with ProtoCrawler.
        We suggest the following next steps:
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Look at the <Link to="/user/learn/quickstart">Quick Start</Link>{" "}
            guide to get an introduction to fuzz testing with ProtoCrawler.
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Look at <Link to="/user/bundles">My Bundles</Link> to get specific
            information and support for each Protocol/Feature.
          </ListItemText>
        </ListItem>
      </List>
    </Container>
  );
}

const stepNames = [
  "Installation",
  "Login & Workspace",
  "Protocols & Features",
  "Next Steps"
];

function SetupWizard(props) {
  const [currentStep, setCurrentStep] = React.useState(0);
  const getComponentForStep = (step) => {
    switch (step) {
      case 0:
        return <DownloadInstructions files={props.data.files} />;
      case 1:
        return <LoginAndWorkspace />;
      case 2:
        return <ProtocolsAndFeatures />;
      case 3:
        return <NextSteps />;
    }
  };

  const nextButton =
    currentStep < stepNames.length - 1 ? (
      <Button variant="contained" onClick={() => setCurrentStep(currentStep + 1)}> Next</Button>
    ) : null;

  const backButton =
    currentStep > 0 ? (
      <Button  variant="contained" onClick={() => setCurrentStep(currentStep - 1)}> Back</Button>
    ) : <Button hidden />;

  return (
    <Box>
      <Stepper activeStep={currentStep}>
        {stepNames.map((name) => {
          return (
            <Step key={name}>
              <StepLabel> {name} </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={{ mt: 5 }}>{getComponentForStep(currentStep)}</Box>
      <Box  variant="outlined" display="flex" sx={{  justifyContent: 'space-between'}}>
        {backButton}
        {nextButton}
      </Box>
    </Box>
  );
}

export default function (props) {
  return (
    <LoadDataContainer getURL="/api/market/store/coreBundle">
      <SetupWizard />
    </LoadDataContainer>
  );
}
