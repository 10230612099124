import {
  LoadDataContainer
} from "../../components/data"




function P2CheckResults(props) {
	console.log("Rendering p2 check data:",props.data)
	return (
			<p> P2 Check results </p>
		)
}


export function P2AdminMainPage(props) {

	return (

			<LoadDataContainer getURL={"/api/admin/p2/check"}>

				<P2CheckResults />
			</LoadDataContainer>
		)
}