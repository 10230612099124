import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  FormHandler,
  LoadDataContainer,
  LoadMultiDataContainer,
  TransferList
} from "../../components/data";
import { Box, Button, TextField } from "@mui/material";

function BundlesTable(props) {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      renderCell: (p) => <Link to={"./" + p.value}>{p.value} </Link>
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      flex: 1
    },
    {
      field: "description",
      headerName: "Description",
      flex: 10
    }
  ];
  const rows = props.data;

  return (
    <div style={{ height: 400 }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}

function idIn(list, id) {
  for (let i in list) if (list[i].id === id) return true;
  return false;
}

function BundleEditForm(props) {
  const setFeaturesRight = (values) => {
    let d = { ...props.data };
    console.log("Set right ", values);

    d.bundle.features = values;

    props.setData(d);
  };

  const setFilesRight = (values) => {
    let d = { ...props.data };
    console.log("Set files right ", values);

    d.bundle.files = values;

    props.setData(d);
  };

  const setLeft = (values) => {
    console.log("Set left ", values);
  };

  const selectedFeatures = props.data.bundle.features.map((it) => it);
  const allFeatures = props.data.allFeatures.map((it) => it);
  const availableFeatures = allFeatures.filter(
    (it) => !idIn(selectedFeatures, it.id)
  );

  const selectedFiles = props.data.bundle.files.map((it) => it);
  const allFiles = props.data.allFiles.map((it) => it);
  const availableFiles = allFiles.filter((it) => !idIn(selectedFiles, it.id));

  const getLabel = (f) => f.id;

  return (
    <Box component="form" onSubmit={props.handleSubmit}>
      <TextField
        margin="normal"
        required
        id="name"
        label="Name"
        name="name"
        inputProps={{
          maxLength: 25
        }}
        defaultValue={props.data?.bundle?.name}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        id="basePrice"
        label="Base Price"
        name="basePrice"
        inputProps={{
          maxLength: 25
        }}
        defaultValue={props.data?.bundle?.basePrice}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="description"
        label="Description"
        name="description"
        inputProps={{
          maxLength: 1000
        }}
        defaultValue={props.data.bundle?.description}
        multiline
        rows={3}
      />

      <TransferList
        left={availableFeatures}
        setLeft={setLeft}
        right={selectedFeatures}
        setRight={setFeaturesRight}
        getLabel={getLabel}
      />

      <TransferList
        left={availableFiles}
        setLeft={setLeft}
        right={selectedFiles}
        setRight={setFilesRight}
        getLabel={getLabel}
      />

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
    </Box>
  );
}

export function EditBundlePage(props) {
  const { id } = useParams();

  const bundleURL = "/api/marketplace/management/bundles/" + id;
  const requests = [
    { name: "bundle", url: bundleURL },
    {
      name: "allFeatures",
      url: "/api/marketplace/management/bundles/allFeatures"
    },
    { name: "allFiles", url: "/api/marketplace/management/bundles/allFiles" }
  ];

  return (
    <LoadMultiDataContainer requests={requests}>
      <FormHandler
        postURL={bundleURL}
        prepData={(data) => ({ ...data.bundle })}
      >
        <BundleEditForm />
      </FormHandler>
    </LoadMultiDataContainer>
  );
}

export function CreateBundlePage(props) {
  const navigate = useNavigate();
  const successHandler = (data, setMessage) => {
    navigate("../" + data.id, {
      state: { message: { severity: "success", text: "Bundle Created" } }
    });
  };

  const bundleURL = "/api/marketplace/management/bundles/createBundle";
  const requests = [
    { name: "bundle", value: { features: [], files: [] } },
    {
      name: "allFeatures",
      url: "/api/marketplace/management/bundles/allFeatures"
    },
    { name: "allFiles", url: "/api/marketplace/management/bundles/allFiles" }
  ];

  return (
    <LoadMultiDataContainer requests={requests}>
      <FormHandler
        postURL={bundleURL}
        prepData={(data) => ({ ...data.bundle })}
        successHandler={successHandler}
      >
        <BundleEditForm />
      </FormHandler>
    </LoadMultiDataContainer>
  );
}

export function BundlesIndexPage(props) {
  return (
    <Box>
      <Button component={Link} to="./create">
        New
      </Button>
      <LoadDataContainer getURL="/api/marketplace/management/bundles/list">
        <BundlesTable />
      </LoadDataContainer>
    </Box>
  );
}
