import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import React from "react";

export function Generate(props) {
  return (
    <Container>
      <Typography>
        <strong> Generate a Test Case Group (.tcg) </strong>
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Double click on the Generator Configuration File (.gcf):
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/doubleclickgcf.gif"}
            alt="Open gcf Example"
            width="900"
            height="100%"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Edit the number of tests by moving the slider:
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/slider.gif"}
            alt="Adjust Slider gcf"
            width="900"
            height="100%"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Alternatively, enter the desired number of tests manually:
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/changeNum.gif"}
            alt="Adjust Num of tests gcf"
            width="900"
            height="100%"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Where relevant, edit the estimated Round Trip Time for the target
            device (RTT):
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/rtt.gif"}
            alt="Adjust RTT in GCF"
            width="900"
            height="100%"
          />
        }
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Finish your selection and click on <strong> Generate Tests </strong>{" "}
            to create a Test Case Group (.tcg) file:
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/generateTes.gif"}
            alt="Generate GCF"
            width="750"
            height="100%"
          />
        }
      </List>
    </Container>
  );
}
