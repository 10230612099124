import { Route } from "react-router-dom";

import { AdminLayout } from "../ProtectedLayout";

import { ModulesPage, CreateModulePage } from "./modules";
import { Elavate, Drop } from "../elavate";
import {
  FilesPage,
  UploadFilesPage,
  EditFilePage
} from "./files";

import {UpdateStatusPage} from "./updates"


import {AdministerUserPage,AdminPurchasePage,SendWelcomePage,GetCookie} from "./useradministration"

import {
  CreateOrganisationPage,
  EditOrganisationPage,
  ListOrganisationsPage,
  CreateOrganisationUserPage
} from "./CreateOrganisation";
import { BundlesIndexPage, CreateBundlePage, EditBundlePage } from "./bundles";

import {P2AdminMainPage} from "./p2"

export default function AdminRoutes(props) {
  return (
    <Route path="/admin" element={<AdminLayout />}>
      <Route path="drop" element={<Drop />} />
      <Route path="bundles">
        <Route index element={<BundlesIndexPage />} />
        <Route path=":id" element={<EditBundlePage />} />
        <Route path="create" element={<CreateBundlePage />} />
      </Route>

      <Route path="files">
        <Route index element={<FilesPage />} />
        <Route path="upload" element={<UploadFilesPage />} />
        <Route path="info/*" element={<EditFilePage />} />
      </Route>

      <Route path="organisation">
        <Route index element={<ListOrganisationsPage />} />
        <Route path="create" element={<CreateOrganisationPage />} />
        <Route path=":id">
          <Route index element={<EditOrganisationPage />} />
          <Route path="createUser" element={<CreateOrganisationUserPage />} />
        </Route>
      </Route>

      <Route path="user" >

        <Route path=":id">
          <Route index element={<AdministerUserPage />} />
          <Route path="adminPurchase" element={<AdminPurchasePage />} />
          <Route path="sendWelcome" element={<SendWelcomePage />} />
          <Route path="getCookie" element={<GetCookie />} />
        </Route>
      </Route>


      <Route path="purchase" >
        <Route path=":pid">
          <Route index element={<AdminPurchasePage />} />
        </Route>
      </Route>

      <Route path="updates" >
        <Route index element={<UpdateStatusPage />} />
      </Route>


      <Route path="p2" >
        <Route index element={<P2AdminMainPage />} />
      </Route>


      {/*<Route path="create" element={<CreateModulePage />} />
      <Route path="modules">
        <Route index element={<ModulesPage />} />
  </Route>*/}
    </Route>
  );
}
