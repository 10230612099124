import { createContext, useState,useContext,useEffect } from 'react';

import { useNavigate,useLocation } from "react-router-dom";
import axios from 'axios';


export const apiClient = axios.create({
  auth: false
})


export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};

export const userDetailsContext = createContext({
  userDetails:null,
  setUserDetails:()=>{}
});

export const UserServiceProvider = (props) => {
  const [userDetails,setUserDetails] = useLocalStorage("user",null)

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

      const reqInterceptor = request => {
        console.log("Intercepted API request",request)
        return request;
      }
      const resInterceptor = response => {
          console.log("Intercepted API response",response)
          return response;
      }

      const errInterceptor = error => {
          console.log("Intercepted error response",error)
          if (error.response.status === 401) {
            console.log("401 response to api call redirecing to login")
            navigate('/login',{state:{from:location},replace: true});
          }

          return Promise.reject(error);
      }


      const reqInterceptorhandle = apiClient.interceptors.request.use(reqInterceptor);
      const respInterceptor = apiClient.interceptors.response.use(resInterceptor, errInterceptor);

      return () => {
        apiClient.interceptors.request.eject(reqInterceptorhandle);
        apiClient.interceptors.response.eject(respInterceptor);
      }

  }, [])


  let from = location.state?.from?.pathname || "/";
  if(!from.startsWith("/user"))
  {
    console.log("Login return page is public, chaning to user index")
    from = "/user/"
  }

  const login = (userinfo) => {
    setUserDetails(userinfo)
    console.log("Post login navigate to ",from)
    navigate(from,{replace:true})
  }

  const logout = () => {
    console.log("Loggin out user...")
    setUserDetails(null)
    navigate("/login")
  }

  const isUserAdmin = () => {
    return true;
  }

  const hasPermission = (permission) => {
    console.log("userDetails",userDetails)
    return false;
  }

  const value = {userDetails,setUserDetails,login,logout,apiClient,isUserAdmin,hasPermission}

  return (
    <userDetailsContext.Provider value={value}>
      {props.children}
    </userDetailsContext.Provider>
  )
}



export const useUserService = () => {
  return useContext(userDetailsContext)
}
