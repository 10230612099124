
import React, { useState, useEffect, useRef } from 'react';


import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';


import {
  //FormHandler,
  LoadDataContainer,
  //LoadMultiDataContainer,
  //TransferList
} from "../../components/data";


import { useUserService } from "../../UserServiceProvider";


function AvailableUdatesTable(props)
{
	let [inProgress,setInProgress] = useState(false)
	const { apiClient } = useUserService();

	function doUpdate(name) {
		console.info("Requesting to do update")
		setInProgress(true)
		apiClient.post("/api/admin/softwaremanagement/releaseUpdate/"+name+"/go").then(resp => setInProgress(false))

	}

	if(inProgress)
		return (<p>Update in progress</p>)


	console.error("available updates",props.data)
	const rows = props.data.map( (update) => (
			<tr>
				<td> {update}</td>
				<td><Button onClick={() => doUpdate(update) }>  Apply </Button> </td>
			</tr>
		))
	return (

	<table>
		<thead>
			<th>Available Updates</th>
		</thead>
		{rows}
	</table>
		)
}

function UpdateStatusBox(props) {

	const { apiClient } = useUserService();

	let [status,setStatus] = useState(null);

	useInterval(() => {
		apiClient.get("/api/admin/softwaremanagement/releaseUpdate/status").then(resp => {
			console.debug("Fetched updated status from server",resp.data)

			setStatus(resp.data)
		})
	},1000)


	if(status == null)
		return (
			<p>Loading.. </p>
		)


	let rows = status.messages.map( (msg) =>
		<tr>
			<tr>
				<td colspan="4">{msg} </td>
			</tr>
		</tr>
	 );

	return (

			<table>
				<thead>
					<tr>
					<th>File:</th>
					<td>{status.updateName}</td>
					<th>Complete:</th>
					<td>{status.done ? "COMPLETE":"RUNNING"}</td>
					</tr>
				</thead>

				<tbody>
					{rows}
				</tbody>
			</table>
		)

}

export function UpdateStatusPage(props) {
	return (
		<Container>
			<Stack>
				<LoadDataContainer getURL="/api/admin/softwaremanagement/listUpdates">
					<AvailableUdatesTable />
				</LoadDataContainer>
				<UpdateStatusBox />
			</Stack>

		</Container>
			)
}


function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}