
import * as React from 'react';


import {Link} from 'react-router-dom'

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import WWWLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';


import {useUserService} from "./../UserServiceProvider"



const axios = require("axios")



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <WWWLink color="inherit" href="https://www.cytal.co.uk/">
        CyTAL
      </WWWLink>{" "}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const  LogIn = (props) => {
  const {login} =  useUserService();

  const [message,setMessage] = React.useState(null);



  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    console.log({
      username: data.get('email'),
      password: "<hidden>"
    });

    axios.post("/api/login",new URLSearchParams({username:data.get('email'),password:data.get('password')}),{
      maxRedirects: 0
    }).then(resp => {
      console.log("Auth success, updating user details");
      console.log(resp)

      axios.get("/api/user/details").then(resp =>{
          console.log("Got user details:\n",resp.data)
          login(resp.data)
      }).catch( error => {
        console.error("Fetching user details failed",error);
        setMessage("Unable to complete authentication process with the server.  Please try again or contact support.")
      })

    }).catch( error => {
      console.log("error")
      console.log(error)
      setMessage(error.response.data.message)
    })
  };

  handleSubmit.bind(this)

  const errorMessage = message

  return (
      <Container component="main" maxWidth="mx">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          {
            message &&
            <Alert severity="error">{errorMessage}</Alert>
          }
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to='/public/requestPasswordReset' variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/public/requestAccess" variant="body2">
                  {"Don't have an account? Request access"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}

export default LogIn;
