import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUserService } from "./../UserServiceProvider";
import {
  AdminAppBar,
  UserAppBar,
  PublicAppBar
} from "./../components/UserMenuBar";

import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import WWWLink from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <WWWLink color="inherit" href="https://www.cytal.co.uk/">
        CyTAL
      </WWWLink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const AdminLayout = () => {
  const { userDetails,isUserAdmin } = useUserService();
  let location = useLocation();

  console.debug("check admin Admin: ", userDetails, isUserAdmin());
  if (!userDetails) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!isUserAdmin()) {
    return <Navigate to="/user/" state={{ from: location }} replace />;
  }
  console.log("Admin: ", userDetails);
  return (
    <React.Fragment>
      <AdminAppBar />
      <Box sx={{ m: 2 }}>
        <Outlet />
      </Box>
      <Copyright />
    </React.Fragment>
  );
};

export const ProtectedLayout = () => {
  const { userDetails } = useUserService();
  let location = useLocation();

  console.debug("User: ", userDetails);
  if (!userDetails) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <React.Fragment>
      <UserAppBar />
      <Box sx={{ m: 2 }}>
        <Outlet />
      </Box>
      <Copyright />
    </React.Fragment>
  );
};

export const PublicLayout = () => {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <PublicAppBar />
      <Box sx={{ m: 2 }}>
        <Outlet />
      </Box>
      <Copyright />
    </React.Fragment>
  );
};
