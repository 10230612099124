import { Route } from "react-router-dom";

import RequestAccess from "./requestAccess";
import {
  RequestPasswordResetPage,
  ResetPasswordPage
} from "./requestPasswordReset";
import { PublicLayout } from "../ProtectedLayout";
export default function PublicRoutes(props) {
  return (
    <Route path="/public" element={<PublicLayout />}>
      <Route path="requestAccess" element={<RequestAccess />} />
      <Route
        path="requestPasswordReset"
        element={<RequestPasswordResetPage />}
      />
      <Route path="resetPassword" element={<ResetPasswordPage />} />
    </Route>
  );
}
