import {
  Box,
  Button,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import React from "react";

export function AnalysisGeneration(props) {
  return (
    <Container>
      <Typography>
        <strong>
          Configure and run an analysis on the Test Execution Set (TES){" "}
        </strong>
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Click on the .tes file tab. </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/clickOnTesTab.gif"}
            alt="Click on TES tab"
            width="900"
            height="100%"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Click on the <strong> Configure Analysis Execution Icon</strong>,
            which will open the Run Analysis Wizard.
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/generateAnalysis.gif"}
            alt="Configure Analysis Icon"
            width="900"
            height="100%"
          />
        }

        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Configure the Run Analysis Wizard with the given .ana file, chains
            and a resultant file location and click <strong> Finish </strong>
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/finishAnalysis.gif"}
            alt="Finish Analysis Wizard"
            width="900"
            height="100%"
          />
        }
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            In the newly generated Analysis Results file (.anr), click{" "}
            <strong> Run </strong> to begin the analysis.
          </ListItemText>
        </ListItem>
        {
          <img
            style={{ padding: "20px" }}
            src={"/quickstart/runAnalysis.gif"}
            alt="Run Analysis"
            width="900"
            height="100%"
          />
        }
      </List>
    </Container>
  );
}
