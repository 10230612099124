import * as React from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import { useUserService } from "../UserServiceProvider";

import { Link } from "@mui/material";


function prettyPrintTime(timestamp) {
  if(timestamp===undefined)
    return ""
  return timestamp.replace("T"," ").split(".")[0]
}

function FeaturesTable(props) {
  const rows = props.data.map((feature) => (
    <TableRow id={feature.name}>
      <TableCell>{feature.name}</TableCell>
      <TableCell>{feature.description}</TableCell>
    </TableRow>
  ));
  return (
    <Table>
      <TableHead></TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
}

export function FilesTable(props) {
  const location = window.location.protocol+"//"+window.location.host
  const rows = props.data.map((file) => (
    <TableRow id={file.name}>
      <TableCell>
        <a
          href={location+"/api/files" + file.id}
          donwload={file.name}
        >
          {file.name}
        </a>
      </TableCell>
      <TableCell>{file.description}</TableCell>
      <TableCell>{prettyPrintTime(file.modifiedTime)}</TableCell>
    </TableRow>
  ));
  return (
    <Table>
      <TableHead></TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
}

function DynamicLoadAccordionFeatures(props) {
  const [data, setData] = React.useState(props.data ? props.data : null);

  const loadData = () => {
    if (data === null) {
      throw new Error("TODO Lzay load");
      //setData(data);
    }
  };

  const content =
    data === null ? <LinearProgress /> : <FeaturesTable data={data} />;

  return (
    <Accordion onChange={loadData}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
}

function DynamicLoadAccordionFiles(props) {
  const [data, setData] = React.useState(props.data ? props.data : null);

  const loadData = () => {
    if (data === null) {
      throw new Error("TODO Lzay load");
      //setData(data);
    }
  };

  const content =
    data === null ? <LinearProgress /> : <FilesTable data={data} />;

  return (
    <Accordion onChange={loadData}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
}

export function MarketBundleCardHeader(props) {
  return (
    <Box>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {props.bundle.type}
      </Typography>
      <Typography variant="h5" component="div">
        {props.bundle.name}
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {props.bundle.tags.join(", ")}
      </Typography>
      <Typography variant="body2">{props.bundle.description}</Typography>
    </Box>
  );
}

export function MarketBundleContentPane(props) {
  return (
    <Box>
      <DynamicLoadAccordionFeatures
        data={props.bundle.features}
        title="Features"
      />
      <DynamicLoadAccordionFiles data={props.bundle.files} title="Files" />
    </Box>
  );
}

export function MarketBundleCardContent(props) {
  return (
    <CardContent>
      <MarketBundleCardHeader bundle={props.bundle} />
      <MarketBundleContentPane bundle={props.bundle} />
    </CardContent>
  );
}

export function MarketBundleCard(props) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <MarketBundleCardContent bundle={props.bundle} />
      <CardActions></CardActions>
    </Card>
  );
}

function MarketBundleCardLoader(props) {
  const { apiClient } = useUserService();
  const [bundle, setBundle] = React.useState(null);

  React.useEffect(() => {
    if (props.bundle) {
      setBundle(props.bundle);
    } else {
      apiClient.get("/api/marketBundles/" + props.bundleId).then((resp) => {
        setBundle(resp.data);
      });
    }
  }, []);

  if (bundle === null) return <LinearProgress />;
  return <MarketBundleCard bundle={bundle} />;
}

export function MarketBundleCardList(props) {
  /*  const items = props.bundles.map((bundle) => (
    <MarketBundleCard bundle={bundle} />
  ));
*/
  const items = [{}];
  return (
    <React.Fragment>
      <MarketBundleCardLoader bundle={props.bundles[0]} />
    </React.Fragment>
  );
}

export default function (props) {
  return <MarketBundleCardLoader bundleId={props.bundleId} />;
}
