import { Container, Typography } from "@mui/material";
import * as React from "react";

import SetupWizard from "./quickstart/QuickStart"

export default function (props) {
  return (
    <SetupWizard />
  );
}
