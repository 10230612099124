import * as React from "react";

import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import WWWLink from "@mui/material/Link";
import { Box } from "@mui/material";

const cards = [
  {
    heading: "Set me up",
    description: "Get help downloading and installing the ProtoCrawler application.",
    link: "/user/setup",
    linkText: "Setup"
  },
  {
    heading: "Get me started",
    description: "Introduction to using ProtoCrawler and getting started with fuzz testing.",
    link: "/user/learn/quickstart",
    linkText: "Learn"
  },
  {
    heading: "I want more",
    description:
      "Get me more protocols, features and configurations to test with.",
    link: "/user/market",
    linkText: "Catalogue"
  },
  {
    heading: "I need help",
    description:
      "Having issues or want support making the best use of ProtoCrawler?",
    link: "/user/support/newTicket",
    linkText: "Support"
  }
];

export default function Album() {
  const cardsItems = cards.map((card) => (
    <Grid key={card.heading} item xs={12} sm={6} md={6}>
      <Card style={{ height: "100%" }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h5" component="h2">
            {card.heading}
          </Typography>
          <Typography>{card.description}</Typography>
        </CardContent>
        <CardActions>
          <Button component={Link} to={card.link} variant="outlined">
            {card.linkText}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  ));

  return (
    <main>
      <Container sx={{ py: 8 }} maxWidth="md">

        <Grid container spacing={4}>
          {cardsItems}
        </Grid>

      </Container>
    </main>
  );
}
