import * as React from 'react'
import {useUserService} from "../../UserServiceProvider"
import Box from '@mui/material/Box';

export default function(props){
  const {apiClient,logout} = useUserService()

  React.useEffect(() =>{
    apiClient.post("/api/logout").then(resp =>{
      logout()
    })
  },[])

  return (
    <Box>
      Securely logging you out...
    </Box>
  )
}
