import * as React from "react";

import { useLocation, Navigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import TextField from "@mui/material/TextField";
import { LinearProgress } from "@mui/material";

import { useUserService } from "../../../UserServiceProvider";
import { MarketBundleCard } from "../../../components/BundleDetails";
import { LoadDataContainer } from "../../../components/data";

const stepNames = [
  "Details",
  "Scope",
  "Pricing",
  "Payment",
  "Purchase",
  "Confirmation"
];

const Details = (props) => {
  return (
    <Container>
      <MarketBundleCard bundle={props.bundle} />
      <Button
        variant="contained"
        onClick={props.handleNext}
        sx={{ mt: 3, ml: 1 }}
      >
        Next
      </Button>
    </Container>
  );
};

const Scope = (props) => {
  const setScope = (scope) => {
    props.setValue("scope", scope);
    props.handleNext();
  };

  const scopes = [
    {
      title: "User",
      price: (props.offer.userPrices.annually / 12).toFixed(2),
      description: ["Individual user license", "Bound to your account"],
      buttonText: "Buy for me",
      buttonVariant: "outlined",
      denominator: "pm",
      type: "USER"
    },
    {
      title: "Organisation",
      price: (props.offer.organisationPrices.annually / 12).toFixed(2),
      description: [
        "Multi user license",
        "Available for all users your organisation",
        "Group discount applied"
      ],
      buttonText: "Buy for everyone",
      buttonVariant: "contained",
      denominator: "pm",
      type: "ORGANISATION"
    }
  ];

  return (
    <Container maxWidth="md" component="main">
      <Grid container spacing={5} alignItems="flex-end">
        {scopes.map((scope) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            key={scope.title}
            xs={6}
            // xs={scope.type === "organisation" ? 12 : 6}
          >
            <Card>
              <CardHeader
                title={scope.title}
                subheader={scope.subheader}
                titleTypographyProps={{ align: "center" }}
                action={scope.type === "organisation" ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center"
                }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700]
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2
                  }}
                >
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    display="block"
                  >
                    from
                  </Typography>
                  <Typography component="h2" variant="h3" color="text.primary">
                    £{scope.price}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    /{scope.denominator}
                  </Typography>
                </Box>
                <ul>
                  {scope.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={scope.buttonVariant}
                  onClick={() => setScope(scope.type)}
                >
                  {scope.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button onClick={props.handleBack} sx={{ mt: 3, ml: 1 }}>
        Back
      </Button>
    </Container>
  );
};

const Pricing = (props) => {
  console.log("Creating prices based on scope ", props.request.scope);
  const prices =
    props.request.scope === "USER"
      ? props.offer.userPrices
      : props.offer.organisationPrices;

  const setSubscription = (type) => {
    let priceToPay;
    switch (type) {
      case "MONTHLY":
        priceToPay = prices.monthly;
        break;
      case "QUARTERLY":
        priceToPay = prices.quarterly;
        break;
      case "ANNUALLY":
        priceToPay = prices.annually;
        break;
      default:
        throw new Error("Unknown type " + type);
    }
    props.setValues({
      subscription: type,
      requestedPrice: priceToPay
    });
    props.handleNext();
  };

  console.log("Creating prices with scope", props.request.scope, prices);
  const tiers = [
    {
      title: "Monthly",
      subheader: "Most flexible",
      price: prices.monthly,
      description: [
        "Maximum flexibility",
        "Get going fast",
        "Cancel any time",
        "Standard support"
      ],
      buttonText: "Select",
      buttonVariant: "outlined",
      denominator: "pm",
      type: "MONTHLY"
    },
    {
      title: "Quarterly",
      subheader: "Most popular",
      price: prices.quarterly,
      description: [
        "3 month recurring subscription",
        "Billed quarterly",
        "Priority support"
      ],
      buttonText: "Select",
      buttonVariant: "contained",
      denominator: "pq",
      type: "QUARTERLY"
    },
    {
      title: "Annual",
      subheader: "Best value",
      price: prices.annually,
      description: [
        "12 month recurring subscription",
        "Billed quarterly or annually",
        "Priority support",
        "Customer success engineer"
      ],
      buttonText: "Select",
      buttonVariant: "outlined",
      denominator: "pa",
      type: "ANNUALLY"
    }
  ];

  return (
    <Container maxWidth="md" component="main">
      <Grid container spacing={5} alignItems="flex-end">
        {tiers.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: "center" }}
                action={tier.type === "annual" ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center"
                }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700]
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2
                  }}
                >
                  <Typography component="h2" variant="h3" color="text.primary">
                    £{tier.price}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    /{tier.denominator}
                  </Typography>
                </Box>
                <ul>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  onClick={() => setSubscription(tier.type)}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button onClick={props.handleBack} sx={{ mt: 3, ml: 1 }}>
        Back
      </Button>
    </Container>
  );
};

const Payment = (props) => {
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
        Select your payment method
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={props.request.payment}
        name="payment"
        onChange={props.handleChange}
      >
        <FormControlLabel value="INVOICE" control={<Radio />} label="Invoice" />
        <FormControlLabel
          value="CREDIT_CARD"
          control={<Radio />}
          label="Credit Card"
        />
      </RadioGroup>

      <Box>
        <Button onClick={props.handleBack} sx={{ mt: 3, ml: 1 }}>
          Back
        </Button>

        <Button
          variant="contained"
          onClick={props.handleNext}
          sx={{ mt: 3, ml: 1 }}
        >
          Next
        </Button>
      </Box>
    </FormControl>
  );
};

const Summary = (props) => {
  return (
    <Box>
      <TextField
        id="confirmation-bundle"
        label="Item"
        defaultValue={props.request.bundleName}
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        id="confirmation-scope"
        label="Scope"
        defaultValue={props.request.scope}
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        id="confirmation-subsciption"
        label="Period"
        defaultValue={props.request.subscription}
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        id="confirmation-payment"
        label="Payment method"
        defaultValue={props.request.payment}
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
    </Box>
  );
};

const Purchase = (props) => {
  return (
    <FormControl>
      <Typography>Confirmation</Typography>

      {props.errorMessage && (<Alert severity="error"> {props.errorMessage}</Alert>) }

      <Summary request={props.request} />
      <Box display="flex">
        <Button
          variant="contained"
          onClick={props.handleBack}
          sx={{ mt: 3, ml: 1 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={props.handleNext}
          sx={{ mt: 3, ml: 1 }}
        >
          Purchase
        </Button>
      </Box>
    </FormControl>
  );
};

const Confirmation = (props) => {
  return (
    <FormControl>
      <Typography>Congratulations, your purchase is confirmed</Typography>

      <Summary request={props.request} />
    </FormControl>
  );
};

function WizardContent(props) {
  const { apiClient } = useUserService();

  const [currentStep, setCurrentStep] = React.useState(0);
  const bundle = props.bundle;

  const offer = props.data;

  const [request, setRequest] = React.useState({
    bundleId: bundle.id,
    subscription: null,
    payment: null,
    scope: null,
    requestedPrice: null
  });

  const [purchaseErrorMessage,setPurchaseErrorMessage] = React.useState(null)

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    console.log("back button pressed");
    setCurrentStep(currentStep - 1);
  };

  const setValue = (name, value) => {
    setRequest({
      ...request,
      [name]: value
    });
  };

  const setValues = (values) => {
    setRequest({
      ...request,
      ...values
    });
  };
  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
  };

  const handleSubmit = () => {
    console.log("Submiting request", request);
    //FIXME Validate
    apiClient.post("/api/market/store/purchase", request).then((a) => {
      handleNext();
    }).catch(error => {
        console.log("Purchase error",error)
        setPurchaseErrorMessage(error.message)
    });
  };

  const getContentForStep = (purchaseErrorMessage) => {
    switch (currentStep) {
      case 0:
        return <Details bundle={bundle} handleNext={handleNext} />;
      case 1:
        return (
          <Scope
            setValue={setValue}
            offer={offer}
            request={request}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <Pricing
            request={request}
            offer={offer}
            setValues={setValues}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 3:
        return (
          <Payment
            request={request}
            offer={offer}
            handleChange={handleChange}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 4:
        return (
          <Purchase
            errorMessage={purchaseErrorMessage}
            request={request}
            handleNext={handleSubmit}
            handleBack={handleBack}
          />
        );
      case 5:
        return <Confirmation request={request} handleNext={handleSubmit} />;
      default:
        throw new Error("No step with number " + currentStep);
    }
  };
  return (
    <Box>
      <Stepper activeStep={currentStep}>
        {stepNames.map((name) => {
          return (
            <Step key={name}>
              <StepLabel> {name} </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box
        display="flex"
        sx={{ m: 4, width: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        {getContentForStep(purchaseErrorMessage)}
      </Box>
    </Box>
  );
}

export default function (props) {
  const location = useLocation();
  if (!location.state) return <Navigate to="../" />;
  return (
    <LoadDataContainer
      getURL="/api/market/store/createPurchaseOffer"
      requestConfig={{
        params: { bundleId: location.state.bundle.id }
      }}
    >
      <WizardContent bundle={location.state.bundle} />
    </LoadDataContainer>
  );
}
