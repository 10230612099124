import * as React from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";

import { FormHandler, LoadDataContainer } from "../../components/data";
import { useUserService } from "../../UserServiceProvider";




function validateEmail(email){
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


export function EditUserDetailsFormContent(props) {
  console.log("EditUserDetailsFormContent.props", props);
  const [errors,setErrors] = React.useState({})


  let onChangeEmail = (ev) => {
    let p1 =  document.getElementById('email').value
    let message = null
    console.error("checking email ",p1,validateEmail(p1));



    if(p1 == null || p1 == "")
      message = null;
    else if( !validateEmail(p1))
      message = "Illegal characters in email address"
    else
      message = null;
    setErrors({
      ...errors,
      "email":message
    })


  }

  return (
    <Box component="form" sx={{ mt: 3 }} onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            defaultValue={props?.data?.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            defaultValue={props?.data?.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            defaultValue={props?.data?.email}

            onChange= {onChangeEmail}
            error={errors["email"] != null}
            helperText={errors["email"]?errors["email"]:""}
          />
        </Grid>
      </Grid>

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Update
      </Button>
    </Box>
  );
}

function EditUserDetailsForm(props) {
  const { userDetails } = useUserService();
  return (
    <LoadDataContainer getURL="/api/user/details">
      <FormHandler postURL="/api/user/update">
        <EditUserDetailsFormContent />
      </FormHandler>
    </LoadDataContainer>
  );
}

export function ChangePasswordFormContent(props) {
  console.log("Change password")
  console.log(props)

  //FIXME This is very crude validation, replace with intergrated form validation
  const [error,setError] = React.useState(null)
  let onChange = (ev) => {
    let p1 =  document.getElementById('password').value
    let p2 =  document.getElementById('confirmPassword').value


    const badChars = ["+","&","=","%"]


    for(let bc in badChars)
    {
      console.log("Checking ",badChars[bc])
      if(p1.includes(badChars[bc]))
      {
        setError("'"+badChars[bc]+"' is not a legal password character")
        return;
      }
    }

    if(p1.length < 8)
    {
      setError("Password is not long enough")
      return;
    }


    if(p2 != "" && (p1 != p2))
      {
        setError("Passwords don't match")
        return;
      }


      if(p2 != "" && (p1 != p2))
      {
        setError("Passwords don't match")
        return;
      }



    setError(null)


  }
  return (
    <Box component="form" sx={{ mt: 3 }} onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange= {onChange}
            error={error != null}
            helperText={error?error:"minimum 8 characters"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
            onChange= {onChange}
            error={error != null}
            helperText={error}
          />
        </Grid>
      </Grid>

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={error != null}>
        Change Password
      </Button>
    </Box>
  );
}

function ChangePasswordForm(props) {
  const [data, setData] = React.useState();
  return (
    <FormHandler postURL="/api/user/changePassword">
      <ChangePasswordFormContent />
    </FormHandler>
  );
}

export function AccountDetails(props) {
  return (
    <Box>
      <EditUserDetailsForm />
      <ChangePasswordForm />
    </Box>
  );
}
