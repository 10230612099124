import { Route } from "react-router-dom";

import { ProtectedLayout } from "../ProtectedLayout";

import Dashboard from "./dashboard";
import Setup from "./setup/setup";
import { AccountDetails } from "./AccountDetails";
import { MarketPage } from "./market/market";
import PurchaseDetails from "./market/purchaseDetails";

import PurchaseBundleWizard from "./market/PurchaseBundleWizard";
import { MyBundlesPage } from "./bundles";
import { Elavate } from "../elavate";
import LogoutPage from './logout'

import QuickStart from "./learn/quickstart";

import NewTicket from "./support/newTicket";

export default function UserRoutes(props) {
  return (
    <Route path="/user" element={<ProtectedLayout />}>
      <Route index element={<Dashboard />} />
      <Route path="setup" element={<Setup />} />
      <Route path="market">
        <Route index element={<MarketPage />} />
        <Route path="purchase" element={<PurchaseBundleWizard />} />
        <Route
          path="purchased/:id/details"
          element={<PurchaseDetails function="purchased" />}
        />
        <Route
          path="organisationPurchased/:id/details"
          element={<PurchaseDetails function="organisationPurchase" />}
        />
      </Route>

      <Route path="learn">
        <Route path="quickstart" element={<QuickStart />} />
      </Route>

      <Route path="bundles">
        <Route index element={<MyBundlesPage />} />
      </Route>

      <Route path="support">
        <Route path="newTicket" element={<NewTicket />} />
      </Route>

      <Route path="account" element={<AccountDetails />} />
      <Route path="elavate" element={<Elavate />} />
      <Route path="logout" element={<LogoutPage />} />
    </Route>
  );
}
