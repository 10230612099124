import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {PublicLayout,ProtectedLayout} from './pages/ProtectedLayout'
import LandingPage from './pages/LandingPage'
import LogIn from './pages/login'

import {UserServiceProvider} from "./UserServiceProvider"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


import './App.css';

import PublicRoutes from "./pages/public/publicRoutes";
import UserRoutes from "./pages/user/UserRoutes";
import AdminRoutes from "./pages/admin/AdminRoutes";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function App() {
  return (
<ThemeProvider theme={darkTheme}>
  <CssBaseline />
  <BrowserRouter>
    <UserServiceProvider >
    <Routes>
      <Route path="/login" element={<LogIn />} />
      <Route element={<PublicLayout />}>
           <Route path="/" element={<LandingPage />} />
         </Route>
         {PublicRoutes()}
         {UserRoutes()}
         {AdminRoutes()}
    </Routes>
    </UserServiceProvider>
  </BrowserRouter>
 </ThemeProvider>
  );
}

export default App;
